// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------


.mod_newslist,
.mod_newsreader {
  margin: var(--margin-content);
  .info {
    color:         var(--color-basic-3);
    font-size:     var(--font-size-s);
    border-top:    2px solid var(--color-tertiary);
    border-bottom: 1px solid var(--color-tertiary);
  }

}

.more,
.back {
  font-size:      var(--font-size-2xs);
  font-weight:    var(--font-weight-bold);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin:         10px 0 50px 0;
  a {
    background:      var(--color-secondary);
    color:           var(--color-basic-0);
    text-decoration: none;
    padding:         3px 7px;
    border-radius:   3px;
  }
}