// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

/* Fixer Header */
#header {
  background: var(--color-basic-0);
  position:   sticky;
  top:        0;
  left:       0;
  z-index:    100;
  width:      100%;
  padding:    0 1rem;
  border-top: 6px solid var(--color-basic-0);
  .inside {
	//height:        120px;
	//margin-bottom: -16px;
	border-bottom: 16px solid var(--color-primary);
	@include medium {
	  //height: 136px;
	}
  }
}

.flex-header {
  display:         flex;
  justify-content: flex-end;
  @include xlarge {
	justify-content: space-between;
  }
}


