// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

#main {
  font-size:             var(--font-size-basic);
  hyphens:               auto;
  hyphenate-limit-chars: auto 5;
  padding:               clamp(3.75rem, 2.2727vw + 3.2955rem, 5rem) 1rem clamp(3rem, 1.3636vw + 2.7273rem, 3.75rem) 1rem;
  @include xlarge {
	font-size: var(--font-size-m);
  }
}
