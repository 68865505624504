// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

#container {
  position: relative;
  width:    100%;
  @include medium {
	max-width:    1300px;
	margin-right: auto;
	margin-left:  auto;
  }
}
