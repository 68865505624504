// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Navigation allgemein
nav,
.nav {
  position: relative;
  ul {
	margin:     0;
	padding:    0;
	list-style: none;
	li {
	  margin:  0;
	  padding: 0;
	}
  }
  a,
  a:hover {
	text-decoration: none;
	white-space:     nowrap;
  }
  @media print {
	display: none;
  }
}

// Hauptnavigation  (zwei Ebenen)
.nav-main {
  font-family: var(--font-family-special);
  font-size:   var(--font-size-basic);
  line-height: 1.2;
  display:     none;
  @include xlarge {
	display:    block;
	margin-top: 2.6875rem;
  }
  @include xxlarge {
	font-size:  var(--font-size-xl);
	margin-top: 3.5rem;
  }
  ul {
	overflow: hidden;
  }
  li {
	letter-spacing: 1px;
	text-transform: uppercase;
  }
  a,
  strong {
	display: block;
  }
  .level_1 {
	color: var(--color-primary);
	@include xlarge {
	  display: flex;
	}
	> li {
	  margin-right: 1.75rem;
	}
	strong,
	a {
	  font-weight: 800;
	}
	a {
	  color: var(--color-primary);
	}
	strong {
	  color: var(--color-secondary);
	}
	a {
	  &:hover,
	  &.trail {
		color: var(--color-secondary);
	  }
	}
  }
  .level_2 {
	background: var(--color-primary);
	color:      var(--color-basic-0);
	font-size:  var(--font-size-2xs);
	position:   absolute;
	left:       auto;
	z-index:    100;
	min-width:  240px;
	max-height: 0;
	@include xxlarge {
	  font-size: var(--font-size-basic);
	}
	> li {
	}
	a,
	strong {
	  font-weight: var(--font-weight-basic);
	  padding:     .625rem 1rem;
	}
	a {
	  color: var(--color-secondary);
	  &:visited {
		color: var(--color-secondary);
	  }
	}
	strong {
	  color: var(--color-basic-0);
	}
	a {
	  &:hover {
		color: var(--color-basic-0);
	  }
	}
  }
  .submenu {
	&:hover {
	  .level_2 {
		max-height: none;
	  }
	}
  }
}

// mobile menu
.mobile_menu {
  max-width: 18rem;
  @include xlarge {
	display: none !important // !important erforderlich wegen js 1px-Fehler in Erweiterung codefog/contao-mobile-menu;
  }
  .inner {
	background: var(--color-primary);
	color:      var(--color-basic-0);
  }
  .nav-main {
	font-family: var(--font-family-basic);
	line-height: 1.2;
	display:     block;
	li {
	  letter-spacing: unset;
	  text-transform: unset;
	  margin:         0;
	}
	.level_1 {
	  color:     var(--color-basic-0);
	  font-size: var(--font-size-s);
	  @include medium {
		font-size: var(--font-size-l);
	  }
	  strong,
	  a {
		font-weight:   var(--font-weight-basic);
		padding:       .625rem 1rem;
		border-bottom: 1px solid var(--color-basic-4);
	  }
	  a {
		background: var(--color-primary-dark);
		color:      var(--color-basic-0);
	  }
	  strong {
		background: var(--color-secondary);
		color:      var(--color-basic-0);
	  }
	  a {
		&:hover,
		&.trail {
		  background: var(--color-secondary);
		  color:      var(--color-basic-0);
		}
	  }
	}
	.level_2 {
	  font-size:  var(--font-size-xs);
	  position:   unset;
	  min-width:  unset;
	  max-height: unset;
	  @include medium {
		font-size: var(--font-size-m);
	  }
	  a,
	  strong {
		background: var(--color-primary-light);
		padding:    .625rem 1rem .625rem 1.6rem;
		@include medium {
		  padding: .625rem 1rem .625rem 1.8rem;
		}
	  }
	  a {
		color: var(--color-basic-0);
		&:visited {
		  color: var(--color-basic-0);
		}
	  }
	  strong {
		color:       var(--color-secondary);
		font-weight: var(--font-weight-bold);
	  }
	  a {
		&:hover {
		  background: var(--color-primary-light);
		  color:      var(--color-secondary);
		}
	  }
	}
  }
  .contact_box {
	font-size: var(--font-size-basic);
	padding:   1rem;
	span {
	  color: var(--color-secondary);
	}
	a {
	  color:   var(--color-basic-2);
	  display: block;
	}
  }
}

// mobile trigger {
.mobile_menu_trigger {
  @include xlarge {
	display: none !important // !important erforderlich wegen js 1px-Fehler in Erweiterung codefog/contao-mobile-menu;
  }
}

// mobile toggler
#toggler-mobile {
  background:     var(--color-basic-5);
  color:          var(--color-basic-0);
  font-size:      var(--font-size-m);
  text-align:     center;
  letter-spacing: 2px;
  text-transform: uppercase;
  display:        block;
  position:       absolute;
  top:            0.9rem;
  right:          158px;
  width:          auto;
  height:         auto;
  padding:        .4375rem .6875rem;
  cursor:         pointer;
  @include medium {
	font-size: var(--font-size-2xl);
	right:     290px;
  }
  i {
	margin-left: 1rem;
  }
}

// Navigation Quicklinks im Header
.nav-quicklinks {
  font-size:   var(--font-size-xs);
  line-height: 1.1;
  display:     none;
  padding-top: .4375rem;
  @include xlarge {
	display: block;
  }
  ul {
	display: flex;
  }
  li {
	margin-right: 1.125rem;
  }
  .active {
	font-weight: 700;
  }
  a {
	color: var(--color-basic-10);
	&:visited {
	  color: var(--color-basic-10);
	}
	&:hover {
	  text-decoration: underline;
	}
  }
}

// Footer Navigation
.nav-footer {
  color:      currentColor;
  text-align: center;
  margin:     var(--margin-content);
  @include medium {
	text-align: unset;
  }
  h3 {
	font-size: var(--font-size-xl);
	margin:    0 0 .25rem 0;
  }
  a {
	color:           var(--color-secondary);
	text-decoration: underline;
	&:visited {
	  color: var(--color-secondary);
	}
	&:hover {
	  text-decoration: none;

	}
  }
}


