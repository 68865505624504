// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Breakpoints
// em verwenden, da rem in media-queries problematisch in manchen Browsern
// px als Orientierung bei Bezugsschriftgroesse 16px
$b-xxsmall:     20em; // 320px
$b-xxsmall-max: 29.9375em;
$b-xsmall:      30em; // 480px
$b-xsmall-max:  37.4375em;
$b-small:       37.5em; // 600px
$b-small-max:   47.9375em;
$b-medium:      48em; //  768px, E+F md
$b-medium-max:  58.6875em;
$b-large:       58.75em; // 940px, E+F lg
$b-large-max:   60.4375em;
$b-xlarge:      60.5em; // 968px
$b-xlarge-max:  73.6875em;
$b-xxlarge:     73.75em; // 1180px

// CSS-Variable global
:root {
  // General Colors
  --color-primary:         #004253; // grünblau
  --color-primary-light:   #004a58;
  --color-primary-dark:    #003843;
  --color-secondary:       #b9d100; // hellgrün
  --color-tertiary:        #a0c3dc;
  // Grayscalling
  --color-basic-0:         #ffffff; //weiss
  --color-basic-1:         #ededed;
  --color-basic-2:         #cccccc;
  --color-basic-3:         #999999;
  --color-basic-4:         #4e4e4e;
  --color-basic-5:         #333333;
  --color-basic-7:         #202020;
  --color-basic-10:        #000000; // black
  // Typography
  --font-family-basic:     'Open Sans', 'Verdana', 'Arial', sans-serif;
  --font-family-headline:  'Bitter', serif;
  --font-family-special:   'Raleway', sans-serif;
  --line-height:           1.4;
  --font-weight-basic:     400;
  --font-weight-bold:      700;
  // keine fluide Typografie, weil eher problematisch für Barrierefreiheit (https://adrianroselli.com/2019/12/responsive-type-and-zoom.html)
  // responsive Typografie ohne Skalierungsfaktor nach Vorgabe Grafiker
  --font-size-2xs:         .75rem; // 12px
  --font-size-xs:          .875rem; // 14px
  --font-size-s:           .9375rem; // 15px
  --font-size-basic:       1rem; // 16px
  --font-size-m:           1.125rem; // 18px
  --font-size-l:           1.25rem; // 20px
  --font-size-xl:          1.375rem; // 22px
  --font-size-2xl:         1.625rem; // 26px
  --font-size-3xl:         1.75rem; // 28px
  --font-size-4xl:         2rem; // 32px
  --font-size-5xl:         2.25rem; // 36px
  --font-size-6xl:         2.875rem; // 46px

  // grid Abstände
  --grid__gutter:          clamp(1rem, 8.75vw + -.75rem, 1.875rem);
  --margin-bottom-content: clamp(2.5rem, 2.2727vw + 2.0455rem, 3.75rem); //  unten zwischen 40px und 60px
  --margin-content:        0 0 var(--margin-bottom-content) 0;
}