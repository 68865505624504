// Breakpoints
// em verwenden, da rem in media-queries problematisch in manchen Browsern
// px als Orientierung bei Bezugsschriftgroesse 16px
$b-xxsmall:     20em !default; // 320px smartphone (portrait)
$b-xxsmall-max: 29.9375em !default;
$b-xsmall:      30em !default; // 480px smartphone (landscape)
$b-xsmall-max:  37.4375em !default;
$b-small:       37.5em !default; // 600px small tablet
$b-small-max:   47.9375em !default;
$b-medium:      48em !default; // 768px tablet (portrait)
$b-medium-max:  63.9375em !default;
$b-large:       64em !default; // 1024px tablet (landscape)
$b-large-max:   89.9375em !default;
$b-xlarge:      90em !default; // 1440px desktop
$b-xlarge-max:  119.9375em !default;
$b-xxlarge:     120em !default; // 1920em
// Hightresolution
$retina:        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" !default;
// variable Breiten
@mixin min($b-min) { // min-width
  @media only screen and (min-width: #{$b-min}) {
	@content;
  }
}

@mixin max($b-max) { // max-width
  @media only screen and (max-width: #{$b-max}) {
	@content;
  }
}

@mixin only($b-min, $b-max) { // min-width bis max-width
  @media only screen and (min-width: #{$b-min}) and (max-width: #{$b-max}) {
	@content;
  }
}

// ab einer Breite
@mixin xxsmall {
  @media only screen and (min-width: #{$b-xxsmall}) {
	@content;
  }
}

@mixin xxsmall-hidpi($ratio: 1.3) { // TODO: ueberarbeiten
  @media only screen and (min-width: #{$b-xxsmall}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxsmall}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxsmall}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xxsmall}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xxsmall}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin xsmall {
  @media only screen and (min-width: #{$b-xsmall}) {
	@content;
  }
}

@mixin xsmall-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (min-width: #{$b-xsmall}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xsmall}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xsmall}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xsmall}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xsmall}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin small {
  @media only screen and (min-width: #{$b-small}) {
	@content;
  }
}

@mixin small-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (min-width: #{$b-small}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-small}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-small}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-small}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-small}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin medium {
  @media only screen and (min-width: #{$b-medium}) {
	@content;
  }
}

@mixin medium-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (min-width: #{$b-medium}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-medium}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-medium}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin large {
  @media only screen and (min-width: #{$b-large}) {
	@content;
  }
}

@mixin large-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (min-width: #{$b-large}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-large}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-large}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-large}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-large}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin xlarge {
  @media only screen and (min-width: #{$b-xlarge}) {
	@content;
  }
}

@mixin xlarge-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (min-width: #{$b-xlarge}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xlarge}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xlarge}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xlarge}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xlarge}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin xxlarge {
  @media only screen and (min-width: #{$b-xxlarge}) {
	@content;
  }
}

@mixin xxlarge-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (min-width: #{$b-xxlarge}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxlarge}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-xxlarge}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-xxlarge}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-xxlarge}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

// bis zu Breite
@mixin max-xxsmall {
  @media only screen and (max-width: #{$b-xxsmall-max}) {
	@content;
  }
}

@mixin max-xxsmall-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (max-width: #{$b-xxsmall-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-xxsmall-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-xxsmall-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (max-width: #{$b-xxsmall-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (max-width: #{$b-xxsmall-max}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin max-xsmall {
  @media only screen and (max-width: #{$b-xsmall-max}) {
	@content;
  }
}

@mixin max-xsmall-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (max-width: #{$b-xsmall-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-xsmall-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-xsmall-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (max-width: #{$b-xsmall-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (max-width: #{$b-xsmall-max}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin max-small {
  @media only screen and (max-width: #{$b-small-max}) {
	@content;
  }
}

@mixin max-small-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (max-width: #{$b-small-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-small-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-small-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (max-width: #{$b-small-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (max-width: #{$b-small-max}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin max-medium {
  @media only screen and (max-width: #{$b-medium-max}) {
	@content;
  }
}

@mixin max-medium-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (max-width: #{$b-medium-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-medium-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-medium-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (max-width: #{$b-medium-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (max-width: #{$b-medium-max}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin max-large {
  @media only screen and (max-width: #{$b-large-max}) {
	@content;
  }
}

@mixin max-large-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (max-width: #{$b-large-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-large-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-large-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (max-width: #{$b-large-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (max-width: #{$b-large-max}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin max-xlarge {
  @media only screen and (max-width: #{$b-xlarge-max}) {
	@content;
  }
}

@mixin max-xlarge-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (max-width: #{$b-xlarge-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-xlarge-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (max-width: #{$b-xlarge-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (max-width: #{$b-xlarge-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (max-width: #{$b-xlarge-max}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

//auschliesslich ein Bereich
@mixin only-xxsmall {
  @media only screen and (min-width: #{$b-xxsmall}) and (max-width: #{$b-xxsmall-max - 1px}) {
	@content;
  }
}

@mixin only-xsmall {
  @media only screen and (min-width: #{$b-xsmall}) and (max-width: #{$b-xsmall-max}) {
	@content;
  }
}

@mixin only-small {
  @media only screen and (min-width: #{$b-small}) and (max-width: #{$b-small-max}) {
	@content;
  }
}

@mixin only-medium {
  @media only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) {
	@content;
  }
}

@mixin only-medium-hidpi($ratio: 1.3) { // TODO ueberarbeiten
  @media only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (min--moz-device-pixel-ratio: $ratio),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-width: #{$b-medium}) and (max-width: #{$b-medium-max}) and (min-resolution: $ratio * 1dppx) {
	@content;
  }
}

@mixin only-large {
  @media only screen and (min-width: #{$b-large}) and (max-width: #{$b-large-max}) {
	@content;
  }
}

@mixin only-xlarge {
  @media only screen and (min-width: #{$b-xlarge}) and (max-width: #{$b-xlarge-max}) {
	@content;
  }
}
