// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Bilder
figure {
  margin:  0;
  padding: 0;
  img {
	display: block; // entfernt Abstand unter Bild -> Firefox
  }
}

img {
  max-width: 100%;
  height:    auto;
  border:    0;
}

.content-image {
  overflow: hidden;
  margin:   var(--margin-content)
}

/* Bildergalerie Standard */
.content-gallery {
  margin:  var(--margin-content);
  padding: 0;
  ul {
	margin-top:      25px;
	list-style-type: outside;

	li {
	  display:  inline-block; /* Zentrierung Gallery Container */
	  float:    none; /* Zentrierung Gallery Container */
	  position: relative;
	  width:    242px;
	  height:   180px;
	  margin:   0 5px 7px 3px;
	}
  }
}

// Footer Logos

.footer-logo-gebaeudetechniker,
.footer-logo-badewelten {
  img {
	margin: 0 auto;
  }
}

.footer-logo-flex {
  display:         flex;
  justify-content: center;

  @include xxlarge {
	justify-content: flex-start;
  }
  :first-child {
	flex: 0 1 200px;
  }
  :last-child {
	flex: 0 1 75px
  }
}

// Audio und Video  übernommen aus responsive.css des Contao Frameworks
.audio_container audio {
  max-width: 100%
}

.video_container video {
  max-width: 100%;
  height:    auto
}


