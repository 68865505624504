// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

.logo {
  flex:      0 1 auto;
  width:  180px;
  margin: 1.5rem 0 1.25rem auto;
  @include medium {
	width: 320px;
  }
  @include xlarge {
	max-width: 250px;
	margin-top: 0;
  }
  @include xxlarge {
	max-width:  320px;
  }
}
