// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------
// Element mit der Klasse Lead, mit und ohne Linie
.lead,
.lead-linie {
  font-size:   var(--font-size-l);
  font-style:  italic;
  line-height: 1.3;
  padding:     0;
  @include medium {
	font-size:   var(--font-size-xl);
	line-height: 1.5;
	padding:     0 0 .75rem 0;
  }
  @include xxlarge {
	font-size: var(--font-size-2xl);
  }
}

.lead-linie {
  @include large {
	padding:    .5rem 0 .75rem 0;
	border-top: .125rem var(--color-primary) solid;
  }
}

