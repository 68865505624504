// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

form {
  margin:  0;
  padding: 0;
}

select, input, textarea {
  line-height: normal;
}

fieldset {
  border: 0;
}

/* Kontaktformular */
#kontaktformular {
  background: var(--color-basic-1);
  font-size:  var(--font-size-s);
  padding:    1.5rem;
  @include large {
	font-size: var(--font-size-basic);
	padding:   2rem;
  }
  input {
	width:         100%;
	margin-bottom: 9px;
	padding:       9px 11px 11px 11px;
	border-top:    2px solid var(--color-basic-5);
	border-right:  0;
	border-bottom: 0;
	border-left:   0;
	&:hover,
	&:focus {
	  background: var(--color-secondary);
	  color:      var(--color-basic-10);
	  border-top: 2px solid var(--color-basic-5);
	}
  }
  textarea {
	width:         100%;
	margin-bottom: 12px;
	padding:       9px 11px 11px 11px;
	border-top:    2px solid var(--color-basic-5);
	border-right:  0;
	border-bottom: 0;
	border-left:   0;
	&:hover,
	&:focus {
	  background: var(--color-secondary);
	  color:      var(--color-basic-10);
	  border-top: 2px solid var(--color-basic-5);
	}
  }
  select {
	color:         #999999;
	display:       block;
	width:         60%;
	margin-bottom: 12px;
	padding:       6px 8px 6px 8px;
	border-top:    2px solid var(--color-basic-5);
	border-right:  0;
	border-bottom: 0;
	border-left:   0;
	&:hover,
	&:focus {
	  background: var(--color-secondary);
	  color:      var(--color-basic-10);
	  border-top: 2px solid var(--color-basic-5);
	}
  }
  .mitgliedschaftsarten {
	margin: 25px 0 10px 0;
	input {
	  width: 20px;
	}
	span {
	  display:      block;
	  float:        left;
	  width:        20%;
	  margin-right: 10px;
	  padding:      4px 0 0 8px;
	  border-top:   2px solid #004253;
	}
  }
  .submit {
	background-color: var(--color-primary);
	color:            var(--color-basic-0);
	font-size:        var(--font-size-l);
	font-weight:      var(--font-weight-bold);
	letter-spacing:   2px;
	width:            75%;
	margin-top:       20px;
	padding:          10px 8px 10px 8px;
	border:           0;
	cursor:           pointer;
	&:hover {
	  background: var(--color-secondary);
	  color:      var(--color-basic-10);
	  border:     0;
	}
  }
}

// Fehlerbehandlung
#kontaktformular {
  p.error {
	color: red;
	&::before {
	  color:        var(--color-basic-5);
	  font-family:  FontAwesome;
	  font-size:    var(--font-size-basic);
	  margin-right: 10px;
	  content:      "\f063";
	  outline:      0 none;
	}
  }
  p.error,
  label.error {
	font-size:   85%;
	font-weight: var(font-weight-bold);
  }
}


