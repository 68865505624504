// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

.row {
  grid-column-gap: var(--grid__gutter);
  grid-row-gap:    0;
  //grid-gap:        var(--grid__gutter);
}
