// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Team Bild  (content-text)
.team-bild {
  --margin-content: 0 0 clamp(1rem, 2.7273vw + .4545rem, 2.5rem) 0;
  h3 {
	font-size: var(--font-size-l);
	&::after {
	  background-color: var(--color-primary);
	  display:          block;
	  width:            40px;
	  height:           2px;
	  margin-top:       10px;
	  content:          "";
	}
  }
}