// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------
// Basis Einstellungen
// Box-Sizing
// css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
// paulirish.com/2012/box-sizing-border-box-ftw
html {
  box-sizing:      border-box;
  scroll-behavior: smooth;
}

* {
  &,
  &::before,
  &::after {
	box-sizing: inherit;
  }
}

// Body
// [1] Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//     navigating between pages that do/do not have enough content to produce
//     scrollbars naturally.
// [2] Ensure the page always fills at least the entire height of the viewport.
// [3] Prevent certain mobile browsers from automatically zooming fonts.
body {
  background:               var(--color-primary);
  color:                    var(--color-basic-0);
  font-family:              var(--font-family-basic);
  font-size:                var(--font-size-basic);
  font-weight:              var(--font-weight-basic);
  line-height:              var(--line-height);
  overflow-y:               scroll; // [1]
  min-height:               100%; // [2]
  -webkit-text-size-adjust: none; // [3]
  -ms-text-size-adjust:     none; // [3]
  @media print {
	background: transparent none;
	font-size:  12pt;
  }
}

// Link
a {
  color: var(--color-primary);
}

a:hover {
  text-decoration: none;
}

// Hervorhebungen
strong {
  font-weight: var(--font-weight-bold);
}

// Hochgestellt
sup {
  font-size: 70%;
}


