// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
.clearfix {
  &::after {
	display: table;
	clear:   both;
	content: "";
  }
}

// nur für Screenreader und Tastaturnutzer sichtbar
.invisible:not(:focus):not(:active) {
  position:    absolute;
  overflow:    hidden;
  clip:        rect(0 0 0 0);
  width:       1px;
  height:      1px;
  white-space: nowrap;
  clip-path:   inset(50%);
}

// nur für Screenreader
.hide {
  position:    absolute;
  overflow:    hidden;
  clip:        rect(0 0 0 0);
  width:       1px;
  height:      1px;
  white-space: nowrap;
  clip-path:   inset(50%);
}

// Text zentrieren
.center {
  text-align: center;
}

// Font Awesome
.fa-times {
  color: var(--color-basic-0);
}

// Klassen übernommen aus responsive.css des Contao Frameworks  (wird wohl hier nicht genutzt)

.aspect, .responsive {
  position: relative;
  height:   0
}

.aspect iframe, .responsive iframe {
  position: absolute;
  top:      0;
  left:     0;
  width:    100%;
  height:   100%
}

.aspect--16\:9, .responsive.ratio-169 {
  padding-bottom: 56.25%
}

.aspect--16\:10, .responsive.ratio-1610 {
  padding-bottom: 62.5%
}

.aspect--21\:9, .responsive.ratio-219 {
  padding-bottom: 42.8571%
}

.aspect--4\:3, .responsive.ratio-43 {
  padding-bottom: 75%
}

.aspect--3\:2, .responsive.ratio-32 {
  padding-bottom: 66.6666%
}





