// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

#footer {
  background:  var(--color-primary);
  color:       var(--color-basic-0);
  width:       100%;
  padding:     2rem 1rem 3rem 1rem;
}