// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

.inside {
  position:  relative;
  max-width: 1500px;
  margin:    0 auto;
  //padding:   0 .9375rem 0 .9375rem;
}

#wrapper {
  background: var(--color-basic-0);
  color:      var(--color-basic-10);
  display:    flex;
  position:   relative;
  flex-flow:  column;
  min-width:  320px;
}
