// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

/* Scrollup */
.scrollup {
  background:  var(--color-primary);
  line-height: 1;
  text-align:  center;
  display:     none;
  position:    fixed;
  top:         0;
  right:       20px;
  z-index:     200;
}

a.scrollup, a.scrollup:link, a.scrollup:visited {
  color:           var(--color-basic-0);
  text-decoration: none;
  margin:          0;
  padding:         1px 15px 4px 15px;
}

a.scrollup::before {
  font-family: FontAwesome;
  font-size:   var(--font-size-4xl);
  content:     "\f106";
}