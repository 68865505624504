// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

.content-download {
  margin:  var(--margin-content);
  padding: 0;
  &.ext-pdf {
	background: none;
	a {
	  background:   url(../../images/iconPDF.svg) left center no-repeat;
	  margin-left:  1.25rem;
	  padding-left: 1.375rem;
	}
  }
}

.content-downloads {
  margin: var(--margin-content);
  ul {
	padding: 0;
	li {
	  margin-left: 1.25rem;
	}
  }
}