// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Link in Buttonoptik

.startseitenlink {
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--color-basic-0);
  padding: 10px 20px;
  background:var(--color-primary);
  margin-top: 15px;
  &:hover {
	text-decoration: none;
	color: var(--color-primary);;
	padding: 10px 20px;
	border-radius: 3px;
	background: var(--color-secondary);;
  }
  &::before {
	content: "\f105";
	font-family: FontAwesome;
	margin-right: 0.5em;
  }
}


