// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/open-sans-v40-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('../../fonts/open-sans-v40-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* open-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/open-sans-v40-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('../../fonts/open-sans-v40-latin-italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/open-sans-v40-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('../../fonts/open-sans-v40-latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/open-sans-v40-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('../../fonts/open-sans-v40-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* bitter-italic - latin */
@font-face {
  font-family: 'Bitter';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/bitter-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Bitter Italic'), local('Bitter-Italic'),
			   url('../../fonts/bitter-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../fonts/bitter-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('../../fonts/bitter-v12-latin-italic.woff') format('woff'), /* Modern Browsers */ url('../../fonts/bitter-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('../../fonts/bitter-v12-latin-italic.svg#Bitter') format('svg'); /* Legacy iOS */
}

/* bitter-700 - latin */
@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/bitter-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Bitter Bold'), local('Bitter-Bold'),
			   url('../../fonts/bitter-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../fonts/bitter-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../../fonts/bitter-v12-latin-700.woff') format('woff'), /* Modern Browsers */ url('../../fonts/bitter-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../../fonts/bitter-v12-latin-700.svg#Bitter') format('svg'); /* Legacy iOS */
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
			   url('../../fonts/raleway-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../fonts/raleway-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../../fonts/raleway-v12-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../../fonts/raleway-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../../fonts/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/raleway-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
			   url('../../fonts/raleway-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../../fonts/raleway-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */ url('../../fonts/raleway-v12-latin-800.woff') format('woff'), /* Modern Browsers */ url('../../fonts/raleway-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */ url('../../fonts/raleway-v12-latin-800.svg#Raleway') format('svg'); /* Legacy iOS */
}

