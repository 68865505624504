// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Abstandsklassen
.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: clamp(.5rem, .9091vw + .3182rem, 1rem);
}

.mt-2 {
  margin-top: clamp(1rem, 1.8182vw + .6364rem, 2rem);
}

.mt-3 {
  margin-top: clamp(1.5rem, 2.7273vw + .9545rem, 3rem);
}

.mt-4 {
  margin-top: clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
}

.mt-5 {
  margin-top: clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
}

.mt-6 {
  margin-top: clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
}

.mb-0 {
  margin-bottom: 0;
  }


.mb-1 {
  margin-bottom: clamp(.5rem, .9091vw + .3182rem, 1rem);
}

.mb-2 {
  margin-bottom: clamp(1rem, 1.8182vw + .6364rem, 2rem);
}

.mb-3 {
  margin-bottom: clamp(1.5rem, 2.7273vw + .9545rem, 3rem);
}

.mb-4 {
  margin-bottom: clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
}

.mb-5 {
  margin-bottom: clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
}

.mb-6 {
  margin-bottom: clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
}

.mtmb-0 {
  margin-top:    0;
  margin-bottom: 0;
}

.mtmb-1 {
  margin-top:    clamp(.5rem, .9091vw + .3182rem, 1rem);
  margin-bottom: clamp(.5rem, .9091vw + .3182rem, 1rem);
}

.mtmb-2 {
  margin-top:    clamp(1rem, 1.8182vw + .6364rem, 2rem);
  margin-bottom: clamp(1rem, 1.8182vw + .6364rem, 2rem);
}

.mtmb-3 {
  margin-top:    clamp(1.5rem, 2.7273vw + .9545rem, 3rem);
  margin-bottom: clamp(1.5rem, 2.7273vw + .9545rem, 3rem);
}

.mtmb-4 {
  margin-top:    clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
  margin-bottom: clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
}

.mtmb-5 {
  margin-top:    clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
  margin-bottom: clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
}

.mtmb-6 {
  margin-top:   clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
  margin-bottom: clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mr-6 {
  margin-right: 6rem;
}

.mlmr-0 {
  margin-right: 0;
  margin-left:  0;
}

.mlmr-1 {
  margin-right: 1rem;
  margin-left:  1rem;
}

.mlmr-2 {
  margin-right: 2rem;
  margin-left:  2rem;
}

.mlmr-3 {
  margin-right: 3rem;
  margin-left:  3rem;
}

.mlmr-4 {
  margin-right: 4rem;
  margin-left:  4rem;
}

.mlmr-5 {
  margin-right: 5rem;
  margin-left:  5rem;
}

.mlmr-6 {
  margin-right: 6rem;
  margin-left:  6rem;
}
