// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Box gün (Inhaltselement content-text)
.startseite-box-aktuelles {
  background: var(--color-primary);
  color:      var(--color-basic-0);
  display:    flex;
  flex-flow:  column;
  height:     calc(100% - var(--margin-bottom-content));
  padding:    1.5rem 1.25rem;
  a {
	color: var(--color-secondary);
  }
  h2,
  h3 {
	font-family: var(--font-family-headline);
	font-size:   var(--font-size-xl);
	font-style:  italic;
	margin:      0 0 1.25rem 0;
	@include medium {
	  font-size: var(--font-size-2xl);
	}
  }
}

// Box grau (Inhaltselement content-text)
.box-blockquote {
  background: var(--color-basic-1);
  color:      var(--color-basic-10);
  font-size:  var(--font-size-xs);
  padding:    1.5625rem;
  &.media--left,
  &.media--right {
	figure {
	  max-width: unset;
	}
  }
  @include xxlarge {
	font-size: var(--font-size-basic);
  }
  // Zitat
  blockquote {
	font-family: var(--font-family-headline);
	font-size:   var(--font-size-xl);
	font-style:  italic;
	line-height: 1.3;
	margin:      0 0 1rem 0;
	padding:     .9375rem 1.875rem 0 0;
	@include medium {
	  font-size: var(--font-size-2xl);
	}
	@include xxlarge {
	  font-size: var(--font-size-3xl);
	}
  }
  figure {
	margin-right:  1.875rem;
	margin-bottom: -.0625rem;
	border-bottom: .375rem var(--color-primary) solid;
  }
}

// Box Termin (Inhaltselement content-text)
[class*="box-termin"] {
  text-align: center;
  padding:    1.5625rem;
  h3,
  h4 {
	color:       var(--color-primary);
	font-family: "Bitter", serif;
	font-size:   var(--font-size-4xl);
	line-height: 1.1;
	margin:      0 0 1.25rem 0;
	@include medium {
	  font-size: var(--font-size-5xl);
	}
  }
}

.box-termin {
  background: transparent url("../../images/box_termin_bad_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-bad {
  background: transparent url("../../images/box_termin_bad_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-heizung {
  background: transparent url("../../images/box_termin_heizung_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-sanitaer {
  background: transparent url("../../images/box_termin_sanitaer_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-lueftung {
  background: transparent url("../../images/box_termin_lueftung_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-solar {
  background: transparent url("../../images/box_termin_solar_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-button {
  background:    var(--color-primary);
  color:         var(--color-basic-0);
  font-size:     var(--font-size-xl);
  line-height:   1.2;
  text-align:    center;
  display:       block;
  margin:        1.875rem 5% 0 5%;
  padding:       25px 0 9px 0;
  border-radius: 4px;
  @include medium {
	font-size: var(--font-size-xl);
	margin:    1.875rem 20% 0 20%;
  }
  @include xlarge {
	font-size: var(--font-size-2xl);
  }

  .fa-mobile {
	color:                     var(--color-basic-0);
	vertical-align:            middle;
	margin-right:              0.9375rem;
	animation-duration:        1.5s;
	animation-delay:           6s;
	animation-iteration-count: 20;
  }
  strong {
	color: var(--color-secondary);
	@include medium {
	  font-size: var(--font-size-4xl);
	}
	@include xlarge {
	  font-size: var(--font-size-5xl);
	}
  }
}

// Box mit Link (Inhaltselement content-text)
.box-startseite {
  .rte {
	text-align: center;
  }
}

