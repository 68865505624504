// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

.mod_rocksolid_slider {
  margin: var(--margin-content);
  #seitenbild & {
	margin: 0;
  }
}