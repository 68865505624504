// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Sitemap
.mod_sitemap {
  margin: var(--margin-content);
  ul {
	margin:          0;
	list-style-type: square;
  }
  .level_1 {
	font-size:    var(--font-size-l);
	padding-left: 1.25rem;
	@include medium {
	  font-size: var(--font-size-xl);
	}
	> li {
	  &:not(.submenu) {
		padding: 0 0 1rem 0;
	  }
	}
  }
  .level_2 {
	font-size: 1rem;
	padding:   0 0 1rem 1rem;
	@include medium {
	  font-size: var(--font-size-m);
	}
  }
}