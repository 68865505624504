// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Kontakt und Öffnungszeiten im Footer  (Inhaltselemente content-text)
// Footer-Navigation siehe _navigation.scss

.footer-kontakt-adresse,
.footer-oeffnungszeiten {
  color:      currentColor;
  text-align: center;
  margin:     0;
  @include medium {
	text-align: unset;
	margin:     var(--margin-content);
  }
  h3 {
	font-size: var(--font-size-xl);
	margin:    0 0 .25rem 0;
  }
}

.footer-kontakt-adresse {
  a {
	color: var(--color-secondary);

	&:visited {
	  color: var(--color-secondary);
	}
	&:hover {
	  color: var(--color-basic-0);

	}
  }
}

// Social Icons (HTML)
.social_box {
  font-size:       var(--font-size-5xl);
  display:         flex;
  justify-content: center;
  margin:          var(--margin-content);
  gap:             1.5rem;
  @include xsmall {
	font-size: var(--font-size-6xl);
  }
  @include medium {
	justify-content: flex-start;
  }
  a,
  a:hover {
	color: currentColor;
  }
}

// eigener Backlink (content-text)
.wivisions {
  font-size:     var(--font-size-2xs);
  text-align:    center;
  margin-bottom: 0;
  @include medium {
	font-size:  var(--font-size-xs);
	text-align: right;
  }
  a {
	color: var(--color-basic-2);
  }
}
