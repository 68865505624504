:root {
  --color-primary: #004253;
  --color-primary-light: #004a58;
  --color-primary-dark: #003843;
  --color-secondary: #b9d100;
  --color-tertiary: #a0c3dc;
  --color-basic-0: #ffffff;
  --color-basic-1: #ededed;
  --color-basic-2: #cccccc;
  --color-basic-3: #999999;
  --color-basic-4: #4e4e4e;
  --color-basic-5: #333333;
  --color-basic-7: #202020;
  --color-basic-10: #000000;
  --font-family-basic: "Open Sans", "Verdana", "Arial", sans-serif;
  --font-family-headline: "Bitter", serif;
  --font-family-special: "Raleway", sans-serif;
  --line-height: 1.4;
  --font-weight-basic: 400;
  --font-weight-bold: 700;
  --font-size-2xs: .75rem;
  --font-size-xs: .875rem;
  --font-size-s: .9375rem;
  --font-size-basic: 1rem;
  --font-size-m: 1.125rem;
  --font-size-l: 1.25rem;
  --font-size-xl: 1.375rem;
  --font-size-2xl: 1.625rem;
  --font-size-3xl: 1.75rem;
  --font-size-4xl: 2rem;
  --font-size-5xl: 2.25rem;
  --font-size-6xl: 2.875rem;
  --grid__gutter: clamp(1rem, 8.75vw + -.75rem, 1.875rem);
  --margin-bottom-content: clamp(2.5rem, 2.2727vw + 2.0455rem, 3.75rem);
  --margin-content: 0 0 var(--margin-bottom-content) 0;
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/open-sans-v40-latin-regular.woff2") format("woff2"), url("../../fonts/open-sans-v40-latin-regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/open-sans-v40-latin-italic.woff2") format("woff2"), url("../../fonts/open-sans-v40-latin-italic.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/open-sans-v40-latin-600.woff2") format("woff2"), url("../../fonts/open-sans-v40-latin-600.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/open-sans-v40-latin-700.woff2") format("woff2"), url("../../fonts/open-sans-v40-latin-700.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* bitter-italic - latin */
@font-face {
  font-family: "Bitter";
  font-style: italic;
  font-weight: 400;
  src: url("../../fonts/bitter-v12-latin-italic.eot"); /* IE9 Compat Modes */
  src: local("Bitter Italic"), local("Bitter-Italic"), url("../../fonts/bitter-v12-latin-italic.eot?#iefix") format("embedded-opentype"), url("../../fonts/bitter-v12-latin-italic.woff2") format("woff2"), url("../../fonts/bitter-v12-latin-italic.woff") format("woff"), url("../../fonts/bitter-v12-latin-italic.ttf") format("truetype"), url("../../fonts/bitter-v12-latin-italic.svg#Bitter") format("svg"); /* Legacy iOS */
}
/* bitter-700 - latin */
@font-face {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/bitter-v12-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Bitter Bold"), local("Bitter-Bold"), url("../../fonts/bitter-v12-latin-700.eot?#iefix") format("embedded-opentype"), url("../../fonts/bitter-v12-latin-700.woff2") format("woff2"), url("../../fonts/bitter-v12-latin-700.woff") format("woff"), url("../../fonts/bitter-v12-latin-700.ttf") format("truetype"), url("../../fonts/bitter-v12-latin-700.svg#Bitter") format("svg"); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/raleway-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Raleway"), local("Raleway-Regular"), url("../../fonts/raleway-v12-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/raleway-v12-latin-regular.woff2") format("woff2"), url("../../fonts/raleway-v12-latin-regular.woff") format("woff"), url("../../fonts/raleway-v12-latin-regular.ttf") format("truetype"), url("../../fonts/raleway-v12-latin-regular.svg#Raleway") format("svg"); /* Legacy iOS */
}
/* raleway-800 - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/raleway-v12-latin-800.eot"); /* IE9 Compat Modes */
  src: local("Raleway ExtraBold"), local("Raleway-ExtraBold"), url("../../fonts/raleway-v12-latin-800.eot?#iefix") format("embedded-opentype"), url("../../fonts/raleway-v12-latin-800.woff2") format("woff2"), url("../../fonts/raleway-v12-latin-800.woff") format("woff"), url("../../fonts/raleway-v12-latin-800.ttf") format("truetype"), url("../../fonts/raleway-v12-latin-800.svg#Raleway") format("svg"); /* Legacy iOS */
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  background: var(--color-primary);
  color: var(--color-basic-0);
  font-family: var(--font-family-basic);
  font-size: var(--font-size-basic);
  font-weight: var(--font-weight-basic);
  line-height: var(--line-height);
  overflow-y: scroll;
  min-height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
@media print {
  body {
    background: transparent none;
    font-size: 12pt;
  }
}

a {
  color: var(--color-primary);
}

a:hover {
  text-decoration: none;
}

strong {
  font-weight: var(--font-weight-bold);
}

sup {
  font-size: 70%;
}

.row {
  grid-column-gap: var(--grid__gutter);
  grid-row-gap: 0;
}

.clearfix::after {
  display: table;
  clear: both;
  content: "";
}

.invisible:not(:focus):not(:active) {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.hide {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.center {
  text-align: center;
}

.fa-times {
  color: var(--color-basic-0);
}

.aspect, .responsive {
  position: relative;
  height: 0;
}

.aspect iframe, .responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect--16\:9, .responsive.ratio-169 {
  padding-bottom: 56.25%;
}

.aspect--16\:10, .responsive.ratio-1610 {
  padding-bottom: 62.5%;
}

.aspect--21\:9, .responsive.ratio-219 {
  padding-bottom: 42.8571%;
}

.aspect--4\:3, .responsive.ratio-43 {
  padding-bottom: 75%;
}

.aspect--3\:2, .responsive.ratio-32 {
  padding-bottom: 66.6666%;
}

h1 {
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  font-style: italic;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

h2 {
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  font-style: italic;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

h3 {
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  font-style: italic;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

h4 {
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  font-style: italic;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

h5 {
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  font-style: italic;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

h6 {
  margin-bottom: 1rem;
  font-family: var(--font-family-headline);
  font-style: italic;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

h1 {
  color: var(--color-primary);
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-basic);
  hyphens: none;
  margin: var(--margin-content);
}
@media only screen and (min-width: 58.75em) {
  h1 {
    font-size: var(--font-size-6xl);
  }
}

h2 {
  font-size: var(--font-size-xl);
  margin: 0 0 1.25rem 0;
}
@media only screen and (min-width: 58.75em) {
  h2 {
    font-size: var(--font-size-2xl);
  }
}
h2::before {
  background-color: currentColor;
  display: block;
  width: 80px;
  height: 2px;
  margin-top: 0;
  margin-bottom: 8px;
  content: "";
}

h3 {
  font-family: var(--font-family-basic);
  font-style: normal;
  font-size: var(--font-size-l);
  margin: 0 0 0.5rem 0;
}

h4 {
  font-family: var(--font-family-basic);
  font-style: normal;
  font-size: var(--font-size-basic);
}

p {
  margin: 0 0 1rem 0;
}

.content-text {
  overflow: hidden;
  margin: var(--margin-content);
}
.content-text.media--above, .content-text.media--below {
  display: flex;
  flex-flow: column;
}
.content-text.media--above figure, .content-text.media--below figure {
  align-self: flex-start;
}
.content-text.media--above figure {
  align-self: flex-start;
  margin: 0 0 1.5rem 0;
}
.content-text.media--below figure {
  order: 3;
  margin: 0.5rem 0 0 0;
}
.content-text.media--left figure {
  float: left;
  margin: 0 1.5rem 1.5rem 0;
  max-width: 40%;
}
.content-text.media--right figure {
  float: right;
  margin: 0 0 1.5rem 1.5rem;
  max-width: 40%;
}
.content-text ul {
  padding: 0;
  list-style: square;
}
.content-text ul li {
  margin-left: 1.25rem;
}

.startseitenlink {
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--color-basic-0);
  padding: 10px 20px;
  background: var(--color-primary);
  margin-top: 15px;
}
.startseitenlink:hover {
  text-decoration: none;
  color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 3px;
  background: var(--color-secondary);
}
.startseitenlink::before {
  content: "\f105";
  font-family: FontAwesome;
  margin-right: 0.5em;
}

.content-download {
  margin: var(--margin-content);
  padding: 0;
}
.content-download.ext-pdf {
  background: none;
}
.content-download.ext-pdf a {
  background: url(../../images/iconPDF.svg) left center no-repeat;
  margin-left: 1.25rem;
  padding-left: 1.375rem;
}

.content-downloads {
  margin: var(--margin-content);
}
.content-downloads ul {
  padding: 0;
}
.content-downloads ul li {
  margin-left: 1.25rem;
}

figure {
  margin: 0;
  padding: 0;
}
figure img {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

.content-image {
  overflow: hidden;
  margin: var(--margin-content);
}

/* Bildergalerie Standard */
.content-gallery {
  margin: var(--margin-content);
  padding: 0;
}
.content-gallery ul {
  margin-top: 25px;
  list-style-type: outside;
}
.content-gallery ul li {
  display: inline-block; /* Zentrierung Gallery Container */
  float: none; /* Zentrierung Gallery Container */
  position: relative;
  width: 242px;
  height: 180px;
  margin: 0 5px 7px 3px;
}

.footer-logo-gebaeudetechniker img,
.footer-logo-badewelten img {
  margin: 0 auto;
}

.footer-logo-flex {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 73.75em) {
  .footer-logo-flex {
    justify-content: flex-start;
  }
}
.footer-logo-flex :first-child {
  flex: 0 1 200px;
}
.footer-logo-flex :last-child {
  flex: 0 1 75px;
}

.audio_container audio {
  max-width: 100%;
}

.video_container video {
  max-width: 100%;
  height: auto;
}

.mod_rocksolid_slider {
  margin: var(--margin-content);
}
#seitenbild .mod_rocksolid_slider {
  margin: 0;
}

.ui-accordion-header:focus {
  outline: 0 none;
}

.ce_accordion .toggler {
  background: transparent;
  color: var(--color-primary-light);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  padding: 8px 2px;
  border-top: 2px var(--color-basic-5) solid;
}
.ce_accordion .toggler:hover {
  text-decoration: underline;
  cursor: pointer;
}

.content-accordion {
  margin: var(--margin-content);
}

.logo {
  flex: 0 1 auto;
  width: 180px;
  margin: 1.5rem 0 1.25rem auto;
}
@media only screen and (min-width: 48em) {
  .logo {
    width: 320px;
  }
}
@media only screen and (min-width: 60.5em) {
  .logo {
    max-width: 250px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 73.75em) {
  .logo {
    max-width: 320px;
  }
}

nav,
.nav {
  position: relative;
}
nav ul,
.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav ul li,
.nav ul li {
  margin: 0;
  padding: 0;
}
nav a,
nav a:hover,
.nav a,
.nav a:hover {
  text-decoration: none;
  white-space: nowrap;
}
@media print {
  nav,
  .nav {
    display: none;
  }
}

.nav-main {
  font-family: var(--font-family-special);
  font-size: var(--font-size-basic);
  line-height: 1.2;
  display: none;
}
@media only screen and (min-width: 60.5em) {
  .nav-main {
    display: block;
    margin-top: 2.6875rem;
  }
}
@media only screen and (min-width: 73.75em) {
  .nav-main {
    font-size: var(--font-size-xl);
    margin-top: 3.5rem;
  }
}
.nav-main ul {
  overflow: hidden;
}
.nav-main li {
  letter-spacing: 1px;
  text-transform: uppercase;
}
.nav-main a,
.nav-main strong {
  display: block;
}
.nav-main .level_1 {
  color: var(--color-primary);
}
@media only screen and (min-width: 60.5em) {
  .nav-main .level_1 {
    display: flex;
  }
}
.nav-main .level_1 > li {
  margin-right: 1.75rem;
}
.nav-main .level_1 strong,
.nav-main .level_1 a {
  font-weight: 800;
}
.nav-main .level_1 a {
  color: var(--color-primary);
}
.nav-main .level_1 strong {
  color: var(--color-secondary);
}
.nav-main .level_1 a:hover, .nav-main .level_1 a.trail {
  color: var(--color-secondary);
}
.nav-main .level_2 {
  background: var(--color-primary);
  color: var(--color-basic-0);
  font-size: var(--font-size-2xs);
  position: absolute;
  left: auto;
  z-index: 100;
  min-width: 240px;
  max-height: 0;
}
@media only screen and (min-width: 73.75em) {
  .nav-main .level_2 {
    font-size: var(--font-size-basic);
  }
}
.nav-main .level_2 a,
.nav-main .level_2 strong {
  font-weight: var(--font-weight-basic);
  padding: 0.625rem 1rem;
}
.nav-main .level_2 a {
  color: var(--color-secondary);
}
.nav-main .level_2 a:visited {
  color: var(--color-secondary);
}
.nav-main .level_2 strong {
  color: var(--color-basic-0);
}
.nav-main .level_2 a:hover {
  color: var(--color-basic-0);
}
.nav-main .submenu:hover .level_2 {
  max-height: none;
}

.mobile_menu {
  max-width: 18rem;
}
@media only screen and (min-width: 60.5em) {
  .mobile_menu {
    display: none !important;
  }
}
.mobile_menu .inner {
  background: var(--color-primary);
  color: var(--color-basic-0);
}
.mobile_menu .nav-main {
  font-family: var(--font-family-basic);
  line-height: 1.2;
  display: block;
}
.mobile_menu .nav-main li {
  letter-spacing: unset;
  text-transform: unset;
  margin: 0;
}
.mobile_menu .nav-main .level_1 {
  color: var(--color-basic-0);
  font-size: var(--font-size-s);
}
@media only screen and (min-width: 48em) {
  .mobile_menu .nav-main .level_1 {
    font-size: var(--font-size-l);
  }
}
.mobile_menu .nav-main .level_1 strong,
.mobile_menu .nav-main .level_1 a {
  font-weight: var(--font-weight-basic);
  padding: 0.625rem 1rem;
  border-bottom: 1px solid var(--color-basic-4);
}
.mobile_menu .nav-main .level_1 a {
  background: var(--color-primary-dark);
  color: var(--color-basic-0);
}
.mobile_menu .nav-main .level_1 strong {
  background: var(--color-secondary);
  color: var(--color-basic-0);
}
.mobile_menu .nav-main .level_1 a:hover, .mobile_menu .nav-main .level_1 a.trail {
  background: var(--color-secondary);
  color: var(--color-basic-0);
}
.mobile_menu .nav-main .level_2 {
  font-size: var(--font-size-xs);
  position: unset;
  min-width: unset;
  max-height: unset;
}
@media only screen and (min-width: 48em) {
  .mobile_menu .nav-main .level_2 {
    font-size: var(--font-size-m);
  }
}
.mobile_menu .nav-main .level_2 a,
.mobile_menu .nav-main .level_2 strong {
  background: var(--color-primary-light);
  padding: 0.625rem 1rem 0.625rem 1.6rem;
}
@media only screen and (min-width: 48em) {
  .mobile_menu .nav-main .level_2 a,
  .mobile_menu .nav-main .level_2 strong {
    padding: 0.625rem 1rem 0.625rem 1.8rem;
  }
}
.mobile_menu .nav-main .level_2 a {
  color: var(--color-basic-0);
}
.mobile_menu .nav-main .level_2 a:visited {
  color: var(--color-basic-0);
}
.mobile_menu .nav-main .level_2 strong {
  color: var(--color-secondary);
  font-weight: var(--font-weight-bold);
}
.mobile_menu .nav-main .level_2 a:hover {
  background: var(--color-primary-light);
  color: var(--color-secondary);
}
.mobile_menu .contact_box {
  font-size: var(--font-size-basic);
  padding: 1rem;
}
.mobile_menu .contact_box span {
  color: var(--color-secondary);
}
.mobile_menu .contact_box a {
  color: var(--color-basic-2);
  display: block;
}

@media only screen and (min-width: 60.5em) {
  .mobile_menu_trigger {
    display: none !important;
  }
}

#toggler-mobile {
  background: var(--color-basic-5);
  color: var(--color-basic-0);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: block;
  position: absolute;
  top: 0.9rem;
  right: 158px;
  width: auto;
  height: auto;
  padding: 0.4375rem 0.6875rem;
  cursor: pointer;
}
@media only screen and (min-width: 48em) {
  #toggler-mobile {
    font-size: var(--font-size-2xl);
    right: 290px;
  }
}
#toggler-mobile i {
  margin-left: 1rem;
}

.nav-quicklinks {
  font-size: var(--font-size-xs);
  line-height: 1.1;
  display: none;
  padding-top: 0.4375rem;
}
@media only screen and (min-width: 60.5em) {
  .nav-quicklinks {
    display: block;
  }
}
.nav-quicklinks ul {
  display: flex;
}
.nav-quicklinks li {
  margin-right: 1.125rem;
}
.nav-quicklinks .active {
  font-weight: 700;
}
.nav-quicklinks a {
  color: var(--color-basic-10);
}
.nav-quicklinks a:visited {
  color: var(--color-basic-10);
}
.nav-quicklinks a:hover {
  text-decoration: underline;
}

.nav-footer {
  color: currentColor;
  text-align: center;
  margin: var(--margin-content);
}
@media only screen and (min-width: 48em) {
  .nav-footer {
    text-align: unset;
  }
}
.nav-footer h3 {
  font-size: var(--font-size-xl);
  margin: 0 0 0.25rem 0;
}
.nav-footer a {
  color: var(--color-secondary);
  text-decoration: underline;
}
.nav-footer a:visited {
  color: var(--color-secondary);
}
.nav-footer a:hover {
  text-decoration: none;
}

form {
  margin: 0;
  padding: 0;
}

select, input, textarea {
  line-height: normal;
}

fieldset {
  border: 0;
}

/* Kontaktformular */
#kontaktformular {
  background: var(--color-basic-1);
  font-size: var(--font-size-s);
  padding: 1.5rem;
}
@media only screen and (min-width: 58.75em) {
  #kontaktformular {
    font-size: var(--font-size-basic);
    padding: 2rem;
  }
}
#kontaktformular input {
  width: 100%;
  margin-bottom: 9px;
  padding: 9px 11px 11px 11px;
  border-top: 2px solid var(--color-basic-5);
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
#kontaktformular input:hover, #kontaktformular input:focus {
  background: var(--color-secondary);
  color: var(--color-basic-10);
  border-top: 2px solid var(--color-basic-5);
}
#kontaktformular textarea {
  width: 100%;
  margin-bottom: 12px;
  padding: 9px 11px 11px 11px;
  border-top: 2px solid var(--color-basic-5);
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
#kontaktformular textarea:hover, #kontaktformular textarea:focus {
  background: var(--color-secondary);
  color: var(--color-basic-10);
  border-top: 2px solid var(--color-basic-5);
}
#kontaktformular select {
  color: #999999;
  display: block;
  width: 60%;
  margin-bottom: 12px;
  padding: 6px 8px 6px 8px;
  border-top: 2px solid var(--color-basic-5);
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
#kontaktformular select:hover, #kontaktformular select:focus {
  background: var(--color-secondary);
  color: var(--color-basic-10);
  border-top: 2px solid var(--color-basic-5);
}
#kontaktformular .mitgliedschaftsarten {
  margin: 25px 0 10px 0;
}
#kontaktformular .mitgliedschaftsarten input {
  width: 20px;
}
#kontaktformular .mitgliedschaftsarten span {
  display: block;
  float: left;
  width: 20%;
  margin-right: 10px;
  padding: 4px 0 0 8px;
  border-top: 2px solid #004253;
}
#kontaktformular .submit {
  background-color: var(--color-primary);
  color: var(--color-basic-0);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  letter-spacing: 2px;
  width: 75%;
  margin-top: 20px;
  padding: 10px 8px 10px 8px;
  border: 0;
  cursor: pointer;
}
#kontaktformular .submit:hover {
  background: var(--color-secondary);
  color: var(--color-basic-10);
  border: 0;
}

#kontaktformular p.error {
  color: red;
}
#kontaktformular p.error::before {
  color: var(--color-basic-5);
  font-family: FontAwesome;
  font-size: var(--font-size-basic);
  margin-right: 10px;
  content: "\f063";
  outline: 0 none;
}
#kontaktformular p.error,
#kontaktformular label.error {
  font-size: 85%;
  font-weight: var(font-weight-bold);
}

/* Scrollup */
.scrollup {
  background: var(--color-primary);
  line-height: 1;
  text-align: center;
  display: none;
  position: fixed;
  top: 0;
  right: 20px;
  z-index: 200;
}

a.scrollup, a.scrollup:link, a.scrollup:visited {
  color: var(--color-basic-0);
  text-decoration: none;
  margin: 0;
  padding: 1px 15px 4px 15px;
}

a.scrollup::before {
  font-family: FontAwesome;
  font-size: var(--font-size-4xl);
  content: "\f106";
}

.lead,
.lead-linie {
  font-size: var(--font-size-l);
  font-style: italic;
  line-height: 1.3;
  padding: 0;
}
@media only screen and (min-width: 48em) {
  .lead,
  .lead-linie {
    font-size: var(--font-size-xl);
    line-height: 1.5;
    padding: 0 0 0.75rem 0;
  }
}
@media only screen and (min-width: 73.75em) {
  .lead,
  .lead-linie {
    font-size: var(--font-size-2xl);
  }
}

@media only screen and (min-width: 58.75em) {
  .lead-linie {
    padding: 0.5rem 0 0.75rem 0;
    border-top: 0.125rem var(--color-primary) solid;
  }
}

.startseite-box-aktuelles {
  background: var(--color-primary);
  color: var(--color-basic-0);
  display: flex;
  flex-flow: column;
  height: calc(100% - var(--margin-bottom-content));
  padding: 1.5rem 1.25rem;
}
.startseite-box-aktuelles a {
  color: var(--color-secondary);
}
.startseite-box-aktuelles h2,
.startseite-box-aktuelles h3 {
  font-family: var(--font-family-headline);
  font-size: var(--font-size-xl);
  font-style: italic;
  margin: 0 0 1.25rem 0;
}
@media only screen and (min-width: 48em) {
  .startseite-box-aktuelles h2,
  .startseite-box-aktuelles h3 {
    font-size: var(--font-size-2xl);
  }
}

.box-blockquote {
  background: var(--color-basic-1);
  color: var(--color-basic-10);
  font-size: var(--font-size-xs);
  padding: 1.5625rem;
}
.box-blockquote.media--left figure, .box-blockquote.media--right figure {
  max-width: unset;
}
@media only screen and (min-width: 73.75em) {
  .box-blockquote {
    font-size: var(--font-size-basic);
  }
}
.box-blockquote blockquote {
  font-family: var(--font-family-headline);
  font-size: var(--font-size-xl);
  font-style: italic;
  line-height: 1.3;
  margin: 0 0 1rem 0;
  padding: 0.9375rem 1.875rem 0 0;
}
@media only screen and (min-width: 48em) {
  .box-blockquote blockquote {
    font-size: var(--font-size-2xl);
  }
}
@media only screen and (min-width: 73.75em) {
  .box-blockquote blockquote {
    font-size: var(--font-size-3xl);
  }
}
.box-blockquote figure {
  margin-right: 1.875rem;
  margin-bottom: -0.0625rem;
  border-bottom: 0.375rem var(--color-primary) solid;
}

[class*=box-termin] {
  text-align: center;
  padding: 1.5625rem;
}
[class*=box-termin] h3,
[class*=box-termin] h4 {
  color: var(--color-primary);
  font-family: "Bitter", serif;
  font-size: var(--font-size-4xl);
  line-height: 1.1;
  margin: 0 0 1.25rem 0;
}
@media only screen and (min-width: 48em) {
  [class*=box-termin] h3,
  [class*=box-termin] h4 {
    font-size: var(--font-size-5xl);
  }
}

.box-termin {
  background: transparent url("../../images/box_termin_bad_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-bad {
  background: transparent url("../../images/box_termin_bad_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-heizung {
  background: transparent url("../../images/box_termin_heizung_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-sanitaer {
  background: transparent url("../../images/box_termin_sanitaer_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-lueftung {
  background: transparent url("../../images/box_termin_lueftung_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-solar {
  background: transparent url("../../images/box_termin_solar_hg.jpg") no-repeat center -18.75rem;
}

.box-termin-button {
  background: var(--color-primary);
  color: var(--color-basic-0);
  font-size: var(--font-size-xl);
  line-height: 1.2;
  text-align: center;
  display: block;
  margin: 1.875rem 5% 0 5%;
  padding: 25px 0 9px 0;
  border-radius: 4px;
}
@media only screen and (min-width: 48em) {
  .box-termin-button {
    font-size: var(--font-size-xl);
    margin: 1.875rem 20% 0 20%;
  }
}
@media only screen and (min-width: 60.5em) {
  .box-termin-button {
    font-size: var(--font-size-2xl);
  }
}
.box-termin-button .fa-mobile {
  color: var(--color-basic-0);
  vertical-align: middle;
  margin-right: 0.9375rem;
  animation-duration: 1.5s;
  animation-delay: 6s;
  animation-iteration-count: 20;
}
.box-termin-button strong {
  color: var(--color-secondary);
}
@media only screen and (min-width: 48em) {
  .box-termin-button strong {
    font-size: var(--font-size-4xl);
  }
}
@media only screen and (min-width: 60.5em) {
  .box-termin-button strong {
    font-size: var(--font-size-5xl);
  }
}

.box-startseite .rte {
  text-align: center;
}

.team-bild {
  --margin-content: 0 0 clamp(1rem, 2.7273vw + .4545rem, 2.5rem) 0;
}
.team-bild h3 {
  font-size: var(--font-size-l);
}
.team-bild h3::after {
  background-color: var(--color-primary);
  display: block;
  width: 40px;
  height: 2px;
  margin-top: 10px;
  content: "";
}

.mod_sitemap {
  margin: var(--margin-content);
}
.mod_sitemap ul {
  margin: 0;
  list-style-type: square;
}
.mod_sitemap .level_1 {
  font-size: var(--font-size-l);
  padding-left: 1.25rem;
}
@media only screen and (min-width: 48em) {
  .mod_sitemap .level_1 {
    font-size: var(--font-size-xl);
  }
}
.mod_sitemap .level_1 > li:not(.submenu) {
  padding: 0 0 1rem 0;
}
.mod_sitemap .level_2 {
  font-size: 1rem;
  padding: 0 0 1rem 1rem;
}
@media only screen and (min-width: 48em) {
  .mod_sitemap .level_2 {
    font-size: var(--font-size-m);
  }
}

.footer-kontakt-adresse,
.footer-oeffnungszeiten {
  color: currentColor;
  text-align: center;
  margin: 0;
}
@media only screen and (min-width: 48em) {
  .footer-kontakt-adresse,
  .footer-oeffnungszeiten {
    text-align: unset;
    margin: var(--margin-content);
  }
}
.footer-kontakt-adresse h3,
.footer-oeffnungszeiten h3 {
  font-size: var(--font-size-xl);
  margin: 0 0 0.25rem 0;
}

.footer-kontakt-adresse a {
  color: var(--color-secondary);
}
.footer-kontakt-adresse a:visited {
  color: var(--color-secondary);
}
.footer-kontakt-adresse a:hover {
  color: var(--color-basic-0);
}

.social_box {
  font-size: var(--font-size-5xl);
  display: flex;
  justify-content: center;
  margin: var(--margin-content);
  gap: 1.5rem;
}
@media only screen and (min-width: 30em) {
  .social_box {
    font-size: var(--font-size-6xl);
  }
}
@media only screen and (min-width: 48em) {
  .social_box {
    justify-content: flex-start;
  }
}
.social_box a,
.social_box a:hover {
  color: currentColor;
}

.wivisions {
  font-size: var(--font-size-2xs);
  text-align: center;
  margin-bottom: 0;
}
@media only screen and (min-width: 48em) {
  .wivisions {
    font-size: var(--font-size-xs);
    text-align: right;
  }
}
.wivisions a {
  color: var(--color-basic-2);
}

.mod_newslist,
.mod_newsreader {
  margin: var(--margin-content);
}
.mod_newslist .info,
.mod_newsreader .info {
  color: var(--color-basic-3);
  font-size: var(--font-size-s);
  border-top: 2px solid var(--color-tertiary);
  border-bottom: 1px solid var(--color-tertiary);
}

.more,
.back {
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-bold);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 10px 0 50px 0;
}
.more a,
.back a {
  background: var(--color-secondary);
  color: var(--color-basic-0);
  text-decoration: none;
  padding: 3px 7px;
  border-radius: 3px;
}

.inside {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}

#wrapper {
  background: var(--color-basic-0);
  color: var(--color-basic-10);
  display: flex;
  position: relative;
  flex-flow: column;
  min-width: 320px;
}

/* Fixer Header */
#header {
  background: var(--color-basic-0);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0 1rem;
  border-top: 6px solid var(--color-basic-0);
}
#header .inside {
  border-bottom: 16px solid var(--color-primary);
}
.flex-header {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (min-width: 60.5em) {
  .flex-header {
    justify-content: space-between;
  }
}

#seitenbild {
  padding: 0 1rem 0 1rem;
}

#container {
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 48em) {
  #container {
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
  }
}

#main {
  font-size: var(--font-size-basic);
  hyphens: auto;
  hyphenate-limit-chars: auto 5;
  padding: clamp(3.75rem, 2.2727vw + 3.2955rem, 5rem) 1rem clamp(3rem, 1.3636vw + 2.7273rem, 3.75rem) 1rem;
}
@media only screen and (min-width: 60.5em) {
  #main {
    font-size: var(--font-size-m);
  }
}

#footer {
  background: var(--color-primary);
  color: var(--color-basic-0);
  width: 100%;
  padding: 2rem 1rem 3rem 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: clamp(0.5rem, 0.9091vw + 0.3182rem, 1rem);
}

.mt-2 {
  margin-top: clamp(1rem, 1.8182vw + 0.6364rem, 2rem);
}

.mt-3 {
  margin-top: clamp(1.5rem, 2.7273vw + 0.9545rem, 3rem);
}

.mt-4 {
  margin-top: clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
}

.mt-5 {
  margin-top: clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
}

.mt-6 {
  margin-top: clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: clamp(0.5rem, 0.9091vw + 0.3182rem, 1rem);
}

.mb-2 {
  margin-bottom: clamp(1rem, 1.8182vw + 0.6364rem, 2rem);
}

.mb-3 {
  margin-bottom: clamp(1.5rem, 2.7273vw + 0.9545rem, 3rem);
}

.mb-4 {
  margin-bottom: clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
}

.mb-5 {
  margin-bottom: clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
}

.mb-6 {
  margin-bottom: clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
}

.mtmb-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mtmb-1 {
  margin-top: clamp(0.5rem, 0.9091vw + 0.3182rem, 1rem);
  margin-bottom: clamp(0.5rem, 0.9091vw + 0.3182rem, 1rem);
}

.mtmb-2 {
  margin-top: clamp(1rem, 1.8182vw + 0.6364rem, 2rem);
  margin-bottom: clamp(1rem, 1.8182vw + 0.6364rem, 2rem);
}

.mtmb-3 {
  margin-top: clamp(1.5rem, 2.7273vw + 0.9545rem, 3rem);
  margin-bottom: clamp(1.5rem, 2.7273vw + 0.9545rem, 3rem);
}

.mtmb-4 {
  margin-top: clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
  margin-bottom: clamp(2rem, 3.6364vw + 1.2727rem, 4rem);
}

.mtmb-5 {
  margin-top: clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
  margin-bottom: clamp(2.5rem, 4.5455vw + 1.5909rem, 5rem);
}

.mtmb-6 {
  margin-top: clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
  margin-bottom: clamp(3rem, 5.4545vw + 1.9091rem, 6rem);
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mr-6 {
  margin-right: 6rem;
}

.mlmr-0 {
  margin-right: 0;
  margin-left: 0;
}

.mlmr-1 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mlmr-2 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.mlmr-3 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.mlmr-4 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.mlmr-5 {
  margin-right: 5rem;
  margin-left: 5rem;
}

.mlmr-6 {
  margin-right: 6rem;
  margin-left: 6rem;
}