// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Eliminiert die blaue Geisterlinie unterhalb des Accordions
.ui-accordion-header:focus {
  outline: 0 none;
}

// Akkordion
// legacy
.ce_accordion {
  .toggler {
	background:  transparent;
	color:       var(--color-primary-light);
	font-size:   var(--font-size-2xl);
	font-weight: var(--font-weight-bold);
	padding:     8px 2px;
	border-top:  2px var(--color-basic-5) solid;
	&:hover {
	  text-decoration: underline;
	  cursor:          pointer;
	}
  }
}

.content-accordion {
  margin: var(--margin-content);
}