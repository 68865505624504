// * 
// * rogger 2024
// * @author Maren Lange
// * 
// * ---------------------------------------------------------

// Überschriften
@for $index from 1 through 6 {
  h#{$index} {
	margin-bottom: 1rem;
	font-family:   var(--font-family-headline);
	font-style:    italic;
	font-weight:   var(--font-weight-bold);
	line-height:   1.2;
  }
}

h1 {
  color:       var(--color-primary);
  font-size:   var(--font-size-4xl);
  font-weight: var(--font-weight-basic);
  hyphens:     none;
  margin:      var(--margin-content);
  @include large{
	font-size: var(--font-size-6xl);
  }
}

h2 {
  font-size: var(--font-size-xl);
  margin:    0 0 1.25rem 0;
  @include large {
	font-size: var(--font-size-2xl);
  }
  &::before {
	background-color: currentColor;
	display:          block;
	width:            80px;
	height:           2px;
	margin-top:       0;
	margin-bottom:    8px;
	content:          "";
  }
}

h3 {
  font-family: var(--font-family-basic);
  font-style:  normal;
  font-size: var(--font-size-l);
  margin:    0 0 .5rem 0;
}

h4 {
  font-family: var(--font-family-basic);
  font-style:  normal;
  font-size: var(--font-size-basic);
}

// Absatz
p {
  margin: 0 0 1rem 0;
}

// Inhaltselement Text
.content-text {
  overflow: hidden;
  margin:   var(--margin-content);
  &.media--above,
  &.media--below {
	display:   flex;
	flex-flow: column;
	figure {
	  align-self: flex-start;
	}
  }
  &.media--above {
	figure {
	  align-self: flex-start;
	  margin:     0 0 1.5rem 0;
	}
  }
  &.media--below {
	figure {
	  order:  3;
	  margin: .5rem 0 0 0;
	}
  }
  &.media--left {
	figure {
	  float:  left;
	  margin: 0 1.5rem 1.5rem 0;
	  max-width: 40%;
	}
  }
  &.media--right {
	figure {
	  float:  right;
	  margin: 0 0 1.5rem 1.5rem;
	  max-width: 40%;
	}
  }
  // Listen
  ul {
	padding:    0;
	list-style: square;
	li {
	  margin-left: 1.25rem;
	}
  }
}








